import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";
import Breadcrumb from './Breadcumb';
import { memberVerify } from "../services/member.service";

function SignIn()
{

  const [defaultValue, setDeafaultValue] = useState({
    email: "",
    password: ""
  });


  const navigate = useNavigate();


  const {
    email, password
  } = defaultValue;

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());
    let formValues = {...values };
    let res = await memberVerify(formValues);
    let numRows=res.data.length;
    if(numRows > 0)
      {
        localStorage.setItem('memberID', res.data[0].memberID);
        localStorage.setItem('member_login_status', 1);
        navigate("/dashboard")
      }else
      {
        alert("Invalid Email or Password");
      }
  };

return (
<>
<Breadcrumb title="" />

      <section className="gray ptb-60">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-5 col-lg-8 col-md-12">
              <div className="signup-screen-wrap">
                <div className="signup-screen-single">
                  <div className="text-center mb-4">
                    <h4 className="m-0 ft-medium">Login Your Account</h4>
                  </div>
                  <form className="submit-form" onSubmit={handleSubmit}>				
                    <div className="form-group">
                      <label className="mb-1">Email</label>
                      <input type="email" className="form-control rounded" placeholder="Email*" defaultValue={email || ""} name="email" id="email" required="required" />
                    </div>
                    <div className="form-group">
                      <label className="mb-1">Password</label>
                      <input type="password" className="form-control rounded" placeholder="Password*" defaultValue={password || ""} name="password" id="password" required="required" />
                    </div>
                    {/* <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="flex-1">
                          <input id="dd" className="checkbox-custom" name="dd" type="checkbox" defaultChecked />
                          <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                        </div>	
                        <div className="eltio_k2">
                          <a href="#" className="theme-cl">Lost Your Password?</a>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <button type="submit" className="btn btn-md full-width theme-bg text-light rounded ft-medium">Sign In</button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   



   </>
);
}

export default SignIn;