import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { getBlogById } from "../services/blog.service";
import Breadcrumb from './Breadcumb';

const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function NewsInfo()
{

  const navigate = useNavigate();
  const { id } = useParams();

  const [blogData, setBlogData] = useState({});

  useEffect(() => {
    const getBlog = async () => {
      const blogData  = await getBlogById(id);      
      setBlogData(blogData.data[0]);
    };
    getBlog();
  }, [id]);
  
return (
<>
<Breadcrumb title={blogData.title} />

<div>
        
        <section className="gray py-5 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                {/* About The Blog */}
                <div className="bg-white rounded mb-4">
                  <div className="jbd-01 px-4 py-4">
                    <div className="jbd-details">
                      <h5 className="ft-bold fs-lg">{blogData.title}</h5>
                      <img src={BASE_API_URL + '/uploads/blogs/' + blogData.thumbnailImage} className="img-fluid"/>
                      <div className="d-block mt-3">
                        <p>{blogData.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              
             
              
              </div>
             
            </div>
          </div>
        </section>
      </div>

   </>
);
}

export default NewsInfo;