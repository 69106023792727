import React from 'react';
import { useEffect } from 'react';
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";

import Header from './components/Header';
import HeaderTransparent from './components/HeaderTransparent';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Businesses from './components/Businesses';
import BusinessesIndustry from './components/BusinessesIndustry'
import BusinessDetail from './components/BusinessDetail';
import Members from './components/Members';
import MemberInfo from './components/MemberInfo';

import Events from './components/Events';
import EventInfo from './components/EventInfo';
import News from './components/News';
import NewsInfo from './components/NewsInfo';
import ContactUs from './components/ContactUs';
import SignIn from './components/SignIn';
import Logout from './components/Logout';
import StartupFund from './components/StartupFund'
import EventGallery from  './components/EventGallery'
import Dashboard from './components/user/DashboardMyProfile';
import DashboardMyBusinesses from './components/user/DashboardMyBusinesses';
import DashboardAddBusiness from './components/user/DashboardAddBusiness';
import DashboardMyBusinessEdit from './components/user/DashboardMyBusinessEdit';
import DashboardMyProfile from './components/user/DashboardMyProfile';
import DashboardChangePassword from './components/user/DashboardChangePassword';


import Layout from './Layout';



function App() {


// useEffect(() => {
//     const script = document.createElement('script');
//     script.src = "//cdn.ckeditor.com/4.5.10/standard/ckeditor.js";
//     script.async = true;
//     document.body.appendChild(script);
//   return () => {
//       document.body.removeChild(script);
//     }
//   }, []);


  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/jquery.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/popper.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/bootstrap.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/slick.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/jquery.magnific-popup.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/dropzone.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/counterup.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/lightbox.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/moment.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/daterangepicker.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);



  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/lightbox.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/jQuery.style.switcher.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "/assets/js/custom.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);



  return (

  <>
    

    <div className="App">
      <div id="main-wrapper">
      
      <BrowserRouter>
      <HeaderTransparent />
       <Routes>
         <Route element={<Layout />} >
           <Route path="/signin" element={<SignIn />} />
           <Route path="/" element={<Home />} />
           <Route path="/about-us" element={<AboutUs />} />
           <Route path="/businesses" element={<Businesses />} />
           <Route path="/business/:id" element={<BusinessDetail />} />
           <Route path="/businesses/industry/:id" element={<BusinessesIndustry />} />
           <Route path="/members" element={<Members />} />
           <Route path="/member/:id" element={<MemberInfo />} />
           <Route path="/event/:id" element={<EventInfo />} />
           <Route path="/events" element={<Events />} />
           <Route path="/press-media" element={<News />} />
           <Route path="/press-media/:id" element={<NewsInfo />} />
           <Route path="/contact-us" element={<ContactUs />} />
           <Route path="/signin" element={<SignIn />} />
           <Route path="/logout" element={<Logout />} />
           <Route path="/startup-fund" element={<StartupFund />} />
           <Route path="/event-gallery" element={<EventGallery />} />
           <Route path="/dashboard" element={<Dashboard />} />
           <Route path="/dashboard-my-businesses" element={<DashboardMyBusinesses />} />
           <Route path="/dashboard-add-business" element={<DashboardAddBusiness />} />
           <Route path="/dashboard-my-business-edit/:id" element={<DashboardMyBusinessEdit />} />
           <Route path="/dashboard-my-profile" element={<DashboardMyProfile />} />
           <Route path="/dashboard-change-password" element={<DashboardChangePassword />} />
           
           
           
           
         </Route>
       </Routes>
       <Footer />
     </BrowserRouter>
      
      <a id="tops-button" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up"></i>
      </a>
      </div>
    </div>

    </>
  );
}

export default App;
