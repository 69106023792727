import Breadcrumb from './Breadcumb';

function StartupFund()
{
return (
<>
<Breadcrumb title="Startup Fund" />

<section className="ptb-60">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">YEA’s 5 Crore Startup Fund</h2>
                <span className="animate-border mb-4"></span>
              </div>
              <div className="text mb-3">
                <p>Unveiling the YEA Startup Fund – a bold initiative with an initial corpus of ₹5 crore, dedicated to nurturing innovation, fostering growth, and promoting entrepreneurial excellence. In collaboration with key ecosystem players such as T-Hub, we inaugurated the startup fund on September 22, 2023. Actively, we are identifying and nurturing promising startups in the dynamic city of Hyderabad.</p>

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <figure className="image bx-shadow">
              <img src="assets/images/startup-fund-img-012.jpg" className="img-fluid rounded" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section className="space gray">
        <div className="container">
          <div className="row align-items-center justify-content-between">
          
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">	
              <div className="m-spaced">
                
                <div className="uli-list-features">
                  <ul>
                    <li>
                    <span className="connector"></span>
                      <div className="list-uiyt">
                        <div className="list-iobk">
                        <span className="eael-feature-list-icon fl-icon-0">

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.1 88c-30.87 0-55.1 25.12-55.1 56c0 31 25.12 56 55.1 56c30.1 0 55.1-25 55.1-56C423.1 113.1 398.1 88 367.1 88zM367.1 168c-13.25 0-23.1-10.75-23.1-24s10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24S381.2 168 367.1 168zM505.1 19.25c-1.125-5.375-6.1-11.25-12.37-12.37C460.6 0 435.4 0 410.4 0c-103.2 0-165 55.25-211.3 128H94.92C78.67 128 59.3 139.9 52.05 154.5L2.65 253.2C1.15 256.6 .225 260.2 .1 264c0 13.25 10.8 24 24.05 24h103.9c52.1 0 95.99 43 95.99 96l-.0125 104c0 13.25 10.75 24 23.1 24c3.75-.125 7.375-.925 10.75-2.55l98.69-49.38c14.62-7.25 26.5-26.58 26.5-42.83V312.8c72.62-46.25 127.1-108.4 127.1-211C512.1 76.5 512.1 51.38 505.1 19.25zM37.12 256l43.62-87.25C82.62 165.1 90.87 160 94.99 160h85.12C166.1 188 148.4 224 132.5 256l.125 .375C131.1 256.4 60.1 256 37.12 256zM351.1 417.2c0 4.125-5.125 12.38-8.75 14.13L256.1 475c0-23.88-.3749-94-.5-95.63L255.1 379.5c31.1-15.88 67.87-33.63 95.87-47.63L351.1 417.2zM249.9 346.6C237.5 306.2 205.7 274.5 165.2 262.1C217.4 158.1 264.1 32 410.5 32c21.12 0 41.25 0 65.37 4.125c4.25 24.38 4.125 44.5 4.125 65.63C479.1 246.6 352.1 295.1 249.9 346.6zM102.4 320c-47.1 0-100.4 39.38-102.2 160L0 512h31.1c171.6-3.375 184.9-115.2 133.7-166.6C150.9 330.4 127.2 320 102.4 320zM155.4 429.4c-12.87 31.12-57.62 49.38-123.4 50.5C31.1 476.4 30.87 352 102.4 352c15.12 0 31.12 6.375 40.87 16.12C155.1 381 164.6 406.8 155.4 429.4z"></path></svg>								</span>
                        </div>
                        <div className="list-uiyt-capt">
                          <h5>Fostering Innovation & Growth</h5>
                          <p>Our goal is to create a dynamic and vibrant entrepreneurial landscape, offering essential support and resources for groundbreaking ideas to flourish. The YEA Startup Fund is more than just an investment; it's a commitment to cultivating a culture of innovation and success. As we embark on this exciting journey, we envision expanding the scale of our startup support in the future.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                    <span className="connector"></span>
                      <div className="list-uiyt">
                        <div className="list-iobk">
                        <span className="eael-feature-list-icon fl-icon-1">

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM319.9 144c39.68 0 71.96 32.3 71.96 72S359.5 288 319.9 288S247.9 255.7 247.9 216S280.2 144 319.9 144zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM160.2 480c3.021-53.41 51.19-96 109.1-96H369.9c58.78 0 106.9 42.59 109.1 96H160.2zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM512 32c26.47 0 48 21.53 48 48S538.5 128 512 128s-48-21.53-48-48S485.5 32 512 32zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM128 32c26.47 0 48 21.53 48 48S154.5 128 128 128S80 106.5 80 80S101.5 32 128 32zM561.1 192H496c-11.16 0-22.08 2.5-32.47 7.438c-7.984 3.797-11.39 13.34-7.594 21.31s13.38 11.39 21.31 7.594C483.3 225.5 489.6 224 496 224h65.08C586.1 224 608 246.7 608 274.7V288c0 8.844 7.156 16 16 16S640 296.8 640 288V274.7C640 229.1 604.6 192 561.1 192zM162.8 228.3c7.938 3.797 17.53 .375 21.31-7.594c3.797-7.969 .3906-17.52-7.594-21.31C166.1 194.5 155.2 192 144 192H78.92C35.41 192 0 229.1 0 274.7V288c0 8.844 7.156 16 16 16S32 296.8 32 288V274.7C32 246.7 53.05 224 78.92 224H144C150.4 224 156.7 225.5 162.8 228.3z"></path></svg>								</span>
                        </div>
                        <div className="list-uiyt-capt">
                          <h5>Exchange of Ideas</h5>
                          <p>We believe in the power of diverse perspectives and encourage the free flow of innovative concepts. Through open dialogue, idea sharing, and intellectual collaboration, we continually bring fresh insights and solutions to the forefront.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="list-uiyt">
                        <div className="list-iobk">
                        <span className="eael-feature-list-icon fl-icon-2">

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 416H32V72c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v360c0 8.84 7.16 16 16 16h488c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM98.34 263.03c-3.12 3.12-3.12 8.19 0 11.31l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0l72.69-72.01 84.69 84.69c6.25 6.25 16.38 6.25 22.63 0l93.53-93.53 44.04 44.04c4.95 4.95 11.03 7.16 17 7.16 12.48 0 24.46-9.7 24.46-24.34V112.19c0-8.94-7.25-16.19-16.19-16.19H344.34c-21.64 0-32.47 26.16-17.17 41.46l44.71 44.71-82.22 82.22-84.63-84.63c-6.23-6.23-16.32-6.25-22.57-.05l-84.12 83.32zM362.96 128H448v85.04L362.96 128z"></path></svg>								</span>
                        </div>
                        <div className="list-uiyt-capt">
                          <h5>Collective Growth</h5>
                          <p>Central to our ideology is the concept of collective growth. When our members succeed, our entire community benefits. We actively seek opportunities for collaboration and synergy, aiming to elevate our entrepreneurial ecosystem.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>







			
    

   </>
);
}

export default StartupFund;