import React, { useEffect, useState }from 'react';
import {Link} from "react-router-dom";
import { NavLink } from "react-router-dom";

function HeaderTransparent()
{
  const [member_login_status, setMember_login_status] = useState('');

  useEffect(() => {
    const member_login_status = localStorage.getItem('member_login_status');
    if (member_login_status) {
      setMember_login_status(member_login_status);
    }
  }, []);
  
  function refreshPage(){ 
    setTimeout(()=>{
      window.location.reload(false);
  }, 0);
  }


return (
<>
<div className="header header-transparent change-logo">
    <div className="container">
      <nav id="navigation" className="navigation navigation-landscape row d-flex">
        <div className="nav-header my-auto col-md-2">
          <a className="nav-brand" href="/">
            <img src="/assets/images/yea-logo.png" className="logo" alt="" />
          </a>
          <div className="nav-toggle"></div>
          <div className="mobile_nav">
            <ul>
              <li>
                <a href="/dashboard" className="theme-cl fs-lg">
                  <i className="lni lni-user"></i>
                </a>
              </li>
              <li>
                <a href="/dashboard-add-business" className="crs_yuo12 w-auto text-white theme-bg">
                  <span className="embos_45">
                    <i className="fas fa-plus me-2"></i>Add Business </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="nav-menus-wrapper my-auto col-md-10">
        
          <ul className="nav-menu">
            <li>
              <NavLink  to="/" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Home</NavLink>
            </li>
            <li>
              <NavLink  to="/about-us" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>About Us</NavLink>
            </li>
            <li>
              <NavLink  to="/businesses" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Businesses</NavLink>
            </li>
            <li>
              <NavLink  to="/members" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Members</NavLink>
            </li>
            <li>
              <NavLink  to="/events" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Events</NavLink>
            </li>
            <li>
              <NavLink  to="/contact-us" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Contact Us</NavLink>
            </li>
            <li><a href="#">More</a>
									<ul className="nav-dropdown nav-submenu">
                    <li> <NavLink  to="/press-media" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Press & Media</NavLink></li>
                    <li> <NavLink  to="/startup-fund" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Startup Fund</NavLink></li>
                    <li> <NavLink  to="/event-gallery" className={({ isActive, isPending }) => isActive ? "active" : isPending ? "" : "" } onClick={ refreshPage }>Event Gallery</NavLink></li>
									</ul>
								</li>

          </ul>
          <ul className="nav-menu nav-menu-social align-to-right">           
            <li className="add-listing">
              { member_login_status == 0 || member_login_status == null ? (
                <Link to="/signin"><i className="fas fa-plus me-2"></i> Signin</Link>
              ) : ( <Link to="/dashboard"><i className="fas fa-plus me-2"></i> My Account</Link> )}
            </li>
          </ul>
          
        </div>
      </nav>
    </div>
  </div>

   </>
);
}

export default HeaderTransparent;