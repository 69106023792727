import React, { useState, useEffect, useContext } from 'react'
import { getBusinesses } from "../services/business.service";
const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function BusinessesListing()
{

  const [businessess, setBusinessess] = useState([]);


  useEffect(() => {
    const fetchBusinessess = async () => {
      const { data, error } = await getBusinesses();      
      if (error) {
        console.log(error);
      } else {
        setBusinessess(data);
      }
    };
    fetchBusinessess();
  }, []);

return (
<>
<section className="ptb-60">
<div className="container">
  <div className="row">
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <div className="sec_title position-relative text-center mb-5">
        <h2 className="ft-bold theme-cl">Businesses</h2>
      </div>
    </div>
  </div>
  <div className="row justify-content-center">
  {businessess.map((data, index) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6 d-flex" key={index}>
      <div className="Goodup-grid-wrap border">
        <div className="Goodup-grid-upper">
          
          <div className="Goodup-grid-thumb">
            <a href={"/business/" + data.businessID} className="d-block text-center m-auto">
              <img src={BASE_API_URL + '/uploads/businesses/' + data.thumbnailImage} className="img-fluid" alt="" />
            </a>
          </div>
        </div>
        <div className="Goodup-grid-fl-wrap">
          <div className="Goodup-caption px-2 py-2">
           
            <h4 className="mb-0 ft-medium medium">
              <a href={"/business/" + data.businessID} className="text-dark fs-md">{data.business_name}
              </a>
            </h4>
            <div className="Goodup-middle-caption mt-3">
              <div className="Goodup-location">
                <i className="fas fa-map-marker-alt"></i>{data.city}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
))}

  </div>
</div>
</section>
</>
);
}

export default BusinessesListing;