import Breadcrumb from './Breadcumb';
import NewsListing from './NewsListing';

function News()
{
return (
<>
<Breadcrumb title="Press & Media" />

<NewsListing />
   </>
);
}

export default News;