
import React, { useEffect, useState } from 'react'
import UserNav from "./UserNav";
import DashboardHeader from "./DashboardHeader";
import { getMemberBusinesses } from "../../services/business.service";
const BASE_API_URL = "https://yeaapi.uniqdigi.online";

const DashboardMyBusinesses = () => {
  let memberID = localStorage.getItem('memberID');
    const [BusinessData, setBusinesses] = useState([]);
    useEffect(() => {
      const fetchBusinesses = async () => {
        const { data, error } = await getMemberBusinesses(memberID);
        if (error) {
          console.log(error);
        } else {
          setBusinesses(data);
        }
      };
      fetchBusinesses();
    }, [memberID]);

return (
<>

<DashboardHeader />

<section className="goodup-dashboard-wrap gray px-4 py-5">
   <UserNav />
        <div className="goodup-dashboard-content">
          <div className="dashboard-tlbar d-block mb-5">
            <div className="row">
              <div className="colxl-12 col-lg-12 col-md-12">
                <h1 className="ft-medium">Manage Businesses</h1>
             
              </div>
            </div>
          </div>
          <div className="dashboard-widg-bar d-block">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="dashboard-list-wraps bg-white rounded mb-4">
                  <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                    <div className="dashboard-list-wraps-flx">
                      <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-file-alt me-2 theme-cl fs-sm" />My Businesses</h4>	
                    </div>
                  </div>
                  <div className="dashboard-list-wraps-body py-3 px-3">
                    <div className="dashboard-listing-wraps">
                    {BusinessData.map((data, index) => (
                      <div className="dsd-single-listing-wraps">
                        <div className="dsd-single-lst-thumb"><img src={BASE_API_URL + '/uploads/businesses/' + data.thumbnailImage} className="img-fluid" alt="" /></div>	
                        <div className="dsd-single-lst-caption">
                          <div className="dsd-single-lst-title"><h5>{data.business_name}</h5></div>
                          <span className="agd-location"><i className="lni lni-map-marker me-1" />{data.address}, {data.city}, {data.state} - {data.postal_code}</span>
                          
                          <div className="dsd-single-lst-footer">
                            <a href={"/dashboard-my-business-edit/" + data.businessID} className="btn btn-edit mr-1"><i className="fas fa-edit me-1" />Edit</a>
                            <a href={"/business/" + data.businessID} className="btn btn-view mr-1"><i className="fas fa-eye me-1" />View</a>
                            
                          </div>
                        </div>
                      </div>
                      ))}

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </section>

   </>
);
}

export default DashboardMyBusinesses;