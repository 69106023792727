import React from 'react'
function UserNav()
{
return (
<>

<a className="mobNavigation" data-bs-toggle="collapse" href="#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
          <i className="fas fa-bars me-2" />Dashboard Navigation
        </a>
<div className="collapse" id="MobNav">
          <div className="goodup-dashboard-nav sticky-top">
            <div className="goodup-dashboard-inner">
              <ul data-submenu-title="Main Navigation">
                
                <li><a href="/dashboard-my-businesses"><i className="lni lni-files me-2" />My Businesses</a></li>
                <li><a href="/dashboard-add-business"><i className="lni lni-add-files me-2" />Add Business</a></li>
                
                
              </ul>
              <ul data-submenu-title="My Accounts">
                <li><a href="/dashboard-my-profile"><i className="lni lni-user me-2" />My Profile </a></li>
                
                
                <li><a href="/logout"><i className="lni lni-power-switch me-2" />Log Out</a></li>
              </ul>
            </div>					
          </div>
        </div>
                        
    

   </>
);
}

export default UserNav;