import React, { useState, useEffect } from 'react'
import { getBlogs } from "../services/blog.service";
const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function NewsListing()
{

  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      const { data, error } = await getBlogs();
      if (error) {
        console.log(error);
      } else {
        setBlogs(data);
      }
    };
    fetchBlogs();
  }, []);

return (
<>
<section className="ptb-60">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div className="sec_title position-relative text-center mb-5">
        <h6 className="theme-cl mb-0">Latest Updates</h6>
        <h2 className="ft-bold">Press & Media</h2>
      </div>
    </div>
  </div>
  <div className="row justify-content-center"> 
  {blogs.map((data, index) => ( 
     <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex" key={index}>
      <div className="gup_blg_grid_box">
        <div className="gup_blg_grid_thumb">
          <a href={"/press-media/" + data.blogID}>
            <img src={BASE_API_URL + '/uploads/blogs/' + data.thumbnailImage} className="img-fluid" alt={data.title} />
          </a>
          <div className="Goodup-rating overlay">
            <div className="Goodup-pr-average mid">{data.name}</div>
					</div>
        </div>
        <div className="gup_blg_grid_caption">
          <div className="blg_title">
            <h4>
              <a href={"/press-media/" + data.blogID}>{data.title}</a>
            </h4>
          </div>
         
        </div>
        
      </div>
    </div> 
))}
    
  </div>
</div>
</section>
</>
);
}

export default NewsListing;