
import React, {  useEffect, useState } from 'react'
import { getMemberById, uploadMemberThumbnail, updateMember } from "../../services/member.service";
import UserNav from "./UserNav";
import DashboardHeader from "./DashboardHeader";
import { useNavigate, useParams } from "react-router-dom";

const BASE_API_URL = "https://yeaapi.uniqdigi.online";
function DashboardMyProfile()
{

  const [defaultValue, setMemberData] = useState({
    full_name: "",
    designation: "",
    company_name: "",
    email: "",
    phone: "",
    profileImage: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    about_notes: "",
    facebook_profile_URL: "",
    twitter_profile_URL: "",
    linkedin_profile_URL: "",
    instagram_profile_URL: "",
  });
    let memberID = localStorage.getItem('memberID');

    useEffect(() => {
      const getMember = async () => {
        const { data } = await getMemberById(memberID);
        if (data) setMemberData({ ...data[0] });
      };
      getMember();
    }, [memberID]);



  const {
    full_name,
designation,
company_name,
email,
phone,
profileImage,
address,
city,
state,
postal_code,
about_notes,
facebook_profile_URL,
twitter_profile_URL,
linkedin_profile_URL,
instagram_profile_URL,
  } = defaultValue;


  const [selectedImage, setSelectedImage] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  
  useEffect(() => {
    let url;
    if (selectedImage) {
      url = URL.createObjectURL(selectedImage);
      setPreviewUrl(url);
    }
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [selectedImage]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target);
    let formData = new FormData(e.target);
    let fileFormData = new FormData();
    let files = e.target[0].files;
    const values = Object.fromEntries(formData.entries());
    const imgName = values.full_name.toLowerCase().replaceAll(/[\s\t]+/g, "-");
    fileFormData.append("imgName", imgName);
    delete values.profileImage;

      if (!!selectedImage) {
        fileFormData.append("profileImage", files[0]);
        let { data, error } = await uploadMemberThumbnail(fileFormData);
        values["profileImage"] = data;
      }
      if (values.profileImage) {
        let formValues = {
          ...values,
          profileImage: values.profileImage
        };

        let { data, error } = await updateMember(values, memberID);
        if (error) throw new Error(error);
      }else
      {
        let formValues = {
          ...values
        };
        let { data, error } = await updateMember(values, memberID);
        if (error) throw new Error(error);
      }
      navigate('/dashboard-my-profile');
  };

return (
<>

<DashboardHeader />

<div className="goodup-dashboard-wrap gray px-4 py-5">
  <UserNav />
        <div className="goodup-dashboard-content">
        <div className="dashboard-tlbar d-block mb-5">
            <div className="row">
              <div className="colxl-12 col-lg-12 col-md-12">
                <h1 className="ft-medium">Hello, {defaultValue.full_name}</h1>
               
              </div>
            </div>
          </div>
          
          <div className="dashboard-widg-bar d-block">
          <form className="submit-form" onSubmit={handleSubmit}>
            <div className="row">
            
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 order-xl-last order-lg-last order-md-last">
                <div className="d-flex bg-white rounded px-3 py-3 mb-3">
                  <div className="dash-figure">
                    <div className="dash-figure-thumb">
                    {(profileImage || previewUrl) && (
              <img
                className="mw-100"
                alt="thumbnail"
                src={
                  previewUrl
                    ? previewUrl
                    : `${BASE_API_URL}/uploads/members/${profileImage}`
                }
              />
            )}
                    </div>
                    <div className="upload-photo-btn">
                      <div className="Uploadphoto">
                        <span><i className="fas fa-upload" /> Upload Photo</span>
                        <input onChange={(e) => { setSelectedImage(e.target.files[0]); }} accept="image/*" name="profileImage" id="profileImage" type={"file"} className="upload" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
                
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-user-check me-2 theme-cl fs-sm" />My Profile</h4>	
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Full Name</label>
                            <input type="text" className="form-control rounded" placeholder="Full Name" name="full_name" defaultValue={full_name || ""} />
                          </div>
                        </div>
                       
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Designation</label>
                            <input type="text" className="form-control rounded" placeholder="Designation" name="designation" defaultValue={designation || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Company Name</label>
                            <input type="text" className="form-control rounded" placeholder="Company Name" name="company_name" defaultValue={company_name || ""} />
                          </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Email ID</label>
                            <input type="text" className="form-control rounded" name="email" placeholder="Email ID" defaultValue={email || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Mobile</label>
                            <input type="text" className="form-control rounded" name="phone" placeholder="Phone" defaultValue={phone || ""} />
                          </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Address</label>
                            <input type="text" className="form-control rounded" name="address" placeholder="Address" defaultValue={address || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">City</label>
                            <input type="text" className="form-control rounded" name="city" placeholder="City" defaultValue={city || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">State</label>
                            <input type="text" className="form-control rounded" name="state" placeholder="Address" defaultValue={state || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Postal Code</label>
                            <input type="text" className="form-control rounded" name="postal_code" placeholder="Postal Code" defaultValue={postal_code || ""} />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">About Notes</label>
                            <textarea className="form-control rounded ht-150" name="about_notes" placeholder="Describe your self"  defaultValue={about_notes || ""} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-user-friends me-2 theme-cl fs-sm" />My Social Links</h4>	
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-facebook theme-cl me-1" />Facebook Profile URL</label>
                            <input type="text" className="form-control rounded" name="facebook_profile_URL" placeholder="Facebook Profile URL" defaultValue={facebook_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-twitter theme-cl me-1" />Twitter Profile URL</label>
                            <input type="text" className="form-control rounded" name="twitter_profile_URL" placeholder="Twitter Profile URL" defaultValue={twitter_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-instagram theme-cl me-1" />Instagram Profile URL</label>
                            <input type="text" className="form-control rounded"name="instagram_profile_URL" placeholder="Instagram Profile URL" defaultValue={instagram_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-linkedin theme-cl me-1" />Linkedin Profile URL</label>
                            <input type="text" className="form-control rounded" name="linkedin_profile_URL" placeholder="Linkedin Profile URL" defaultValue={linkedin_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <button className="btn theme-bg rounded text-light">Save Changes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               
              </div>
              
            </div>
            </form>
          </div>
         
        </div>
      </div>
    

   </>
);
}

export default DashboardMyProfile;