import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { getBusinessById } from "../services/business.service";
import Breadcrumb from './Breadcumb';

const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function BusinessDetail()
{

  const navigate = useNavigate();
  const { id } = useParams();

  const [businessData, setBusinessData] = useState({});

  useEffect(() => {
    const getBusiness = async () => {
      const businessData  = await getBusinessById(id);      
      setBusinessData(businessData.data[0]);
    };
    getBusiness();
  }, [id]);
  
return (
<>
<Breadcrumb title={businessData.business_name} />

<div>
        
        <section className="gray py-5 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                {/* About The Business */}
                <div className="bg-white rounded mb-4">
                  <div className="jbd-01 px-4 py-4">
                    <div className="jbd-details">
                      <h5 className="ft-bold fs-lg">About the Business</h5>
                      <div className="d-block mt-3">
                        <p>{businessData.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              
             
              
              </div>
              {/* Sidebar */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                
                <div className="jb-apply-form bg-white rounded py-4 px-4 box-static mb-4">
                  <div className="uli-list-info">
                    <ul>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-user" /></div>
                          <div className="list-uiyt-capt"><h5>Owner/Founder Name</h5><p>{businessData.founder_name}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-industry" /></div>
                          <div className="list-uiyt-capt"><h5>Industry/Sector</h5><p>{businessData.industryID}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-calendar-check" /></div>
                          <div className="list-uiyt-capt"><h5>Year Established</h5><p>{businessData.year_established}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-users" /></div>
                          <div className="list-uiyt-capt"><h5>Number of Employees</h5><p>{businessData.no_of_employees}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-money-bill" /></div>
                          <div className="list-uiyt-capt"><h5>Annual Revenue</h5><p>{businessData.annual_revenue}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-book" /></div>
                          <div className="list-uiyt-capt"><h5>Business Type</h5><p>{businessData.business_type}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-building" /></div>
                          <div className="list-uiyt-capt"><h5>Target Market</h5><p>{businessData.target_market}</p></div>
                        </div>
                      </li>
                      
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-globe" /></div>
                          <div className="list-uiyt-capt"><h5>Website URL</h5><p>{businessData.website}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-envelope" /></div>
                          <div className="list-uiyt-capt"><h5>eMail</h5><p>{businessData.email}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-phone" /></div>
                          <div className="list-uiyt-capt"><h5>Call Us</h5><p>{businessData.office_phone}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-map-marker-alt" /></div>
                          <div className="list-uiyt-capt"><h5>Address</h5><p>{businessData.address}, {businessData.city}, {businessData.state} - {businessData.postal_code}</p></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>
      </div>

   </>
);
}

export default BusinessDetail;