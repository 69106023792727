import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";

const Logout = () => {
  
    const navigate = useNavigate();
    useEffect(() => {
      localStorage.setItem('member_login_status', 0);
      navigate('/signin');
    }, []);

}

export default Logout
