import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { getMemberById } from "../../services/member.service";
const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function DashboardHeader()
{

  const navigate = useNavigate();
  useEffect(() => {
    let member_login_status = localStorage.getItem('member_login_status');
    let memberID = localStorage.getItem('memberID');
    if(member_login_status == 0 || member_login_status == null || memberID == 0)
    {
        navigate('/signin');
      }
      
  }, []);

  const [memberData, setMemberData] = useState({});

  let memberID = localStorage.getItem('memberID');

  useEffect(() => {
    const getMember = async () => {
      const memberData  = await getMemberById(memberID);      
      setMemberData(memberData.data[0]);
    };
    getMember();
  }, [memberID]);

return (
<>


<section className="bg-cover position-relative pt-120 pb-60" style={{background: 'red url(/assets/images/Head-bg-img-1.jpg) no-repeat'}} data-overlay={3}>
        
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="dashboard-head-author-clicl">
                <div className="dashboard-head-author-thumb">
                  <img src={BASE_API_URL + "/uploads/members/" + memberData.profileImage} className="img-fluid" alt="{memberData.full_name}" />
                </div>
                <div className="dashboard-head-author-caption">
                  <div className="dashploio"><h4>{memberData.full_name}</h4></div>
                  <div className="dashploio"><p className="agd-location"><i className="fa fa-address-book me-1" />{memberData.designation}</p></div>
                  <div className="dashploio"><p className="agd-location"><i className="fa fa-building me-1" />{memberData.company_name}</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    

   </>
);
}

export default DashboardHeader;