import React, { useState, useEffect, useContext } from 'react'
import Breadcrumb from './Breadcumb';
import BusinessesListing from './BusinessesListing';
import { getIndustries } from "../services/industry.service";

function Businesses()
{

   const [industries, setIndustries] = useState([]);


  useEffect(() => {
    const fetchBusinessess = async () => {
      const { data, error } = await getIndustries();      
      if (error) {
        console.log(error);
      } else {
         setIndustries(data);
      }
    };
    fetchBusinessess();
  }, []);

return (
<>

<Breadcrumb title="Business" />

<section className="cats-filters py-3">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						
						<div className="col-xl-12 col-lg-12 col-md-12 col-12">
							<div className="Goodup-all-drp text-center">
								
								<div className="Goodup-single-drp small text-center my-auto">
									<div className="btn-group">
										<button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Industries</button>
										<ul className="dropdown-menu">
                              {industries.map((data, index) => (
											<li key={index}><a className="dropdown-item" href={"/businesses/industry/" + data.industryID}>{data.name}</a></li>
                              ))}
										</ul>
									</div>
								</div>
							
							</div>
						</div>
						
					</div>
				</div>
			</section>
			<div className="clearfix"></div>

<BusinessesListing />

   </>
);
}

export default Businesses;