import Breadcrumb from './Breadcumb';
import MembersListing from './MembersListing';

function Members()
{
return (
<>
<Breadcrumb title="Members"/>

<MembersListing  memberType="Member"  sectionTitle="Board Members"/>

   </>
);
}

export default Members;