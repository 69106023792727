import Breadcrumb from './Breadcumb';
import EventsListing from './EventsListing';

function Events()
{
return (
<>
<Breadcrumb title="Events" />

<EventsListing />

   </>
);
}

export default Events;